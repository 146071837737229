$primary: #0224A1;
$secondary: #FFFFFF;
html, body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  margin: 0;
}
body {
  display: flex;
	flex-direction: column;
  color: $secondary;

  #content {
    height: calc(100vh - 5rem);
    display: flex;
    flex-direction: column;

    #content-left, #content-right {
      flex: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #content-left {
      background-color: $secondary;

      > img {
        width: 40%;
      }
    }
    
    #content-right {
      background-color: $primary;
      flex-direction: column;

      > p {
        margin: 0;
        font-size: 3em;
        text-align: center;
        &.info {    
          font-weight: bold;
          margin-top: 3rem;
          font-size: 1.3em;
          a {
            color: $secondary;
          }
        }
      }
    }
    @media screen and (min-width: 768px) {

        flex-direction: row;

      #content-right{ 
        > p {
          font-size: 4em;
          &.info {    
            font-size: 1em;
          }
        }
      }
    }
  }

  footer {
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;

    p {
      margin: 0;
      font-size: 0.9em;
    }
  }
}